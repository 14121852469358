<template>
  <div class="d-flex">
    <div
      class="confirmed-btn pointer"
      :class="booking.unconfirmedParticipantsCount === 0 ? 'green-confirmed' : 'red-confirmed'"
      @click.stop="updateConfirmation"
    >
      <label class="text-center mb-0 confirmed-btn-text pointer">
        {{ label }}
      </label>
    </div>
    <d-modal
      :modalId="`confirm-${booking.id}`"
      :text="text"
      :hide="hide"
      :title="title"
      :display="display"
      @on:cancel="hide = !hide"
      @on:confirm="onConfirm"
    />
  </div>
</template>
<script>
import {confirmBookingParticipants} from "@api/doinsport/services/bookings/booking.api";
import {hydrate} from "@/utils/form";

export default {
  data: () => ({
    display: false,
    hide: false,
  }),
  computed: {
    absentLabel() {
      if (this.booking.unconfirmedParticipantsCount === 1 ) {
        return this.$t('views.bookings.index.multiple.one-absent');
      } else {
        return this.$t('views.bookings.index.multiple.many-absent');
      }
    },
    label() {
      return this.booking.unconfirmedParticipantsCount > 0
        ?
        `${this.booking.unconfirmedParticipantsCount} ${this.absentLabel}`
        :
        this.$t('views.bookings.index.multiple.present-booking')
        ;
    },
    confirmation() {
      return this.booking.unconfirmedParticipantsCount === 0;
    },
    title() {
      return this.$t(`views.bookings.index.multiple.${this.confirmation ? 'un-confirmation' : 'confirmation'}`);
    },
    text() {
      const confirm = this.$t(`views.bookings.index.multiple.${this.confirmation ? 'un-confirm' : 'confirm'}`);
      return this.$t('views.bookings.index.multiple.confirmation-text', {confirm: confirm});
    }
  },
  props: {
    booking: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    updateConfirmation() {
      if (this.confirmation) {
        this.$nextTick(() => {
          this.display = !this.display;
        })
      } else {
        this.onConfirm();
      }
    },
    onConfirm() {
      confirmBookingParticipants(this.booking.id, !this.confirmation)
        .then((response) => {
          hydrate(this.booking, response.data);
        })
        .finally(() => {
          this.hide = !this.hide;
        })
      ;
    }
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/planning/_confirmed-participant-btn";

.shadow-block {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-right: 10px;
  border-radius: 2px;
  padding: 6px 10px;
  font-family: Avenir sans-serif;
  font-weight: bold;
}
</style>
