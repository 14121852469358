import {_get, _post, _put, clubId} from "@api/doinsport/services/httpService";
import {ARRAY_BUFFER} from "@api/doinsport/config";

import {URL} from "./index";

export const getClubBookings = (itemsPerPage, page, search, canceled, date, operator, extraFilters = '') =>
  _get(
    URL + '?club.id=' + clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page +
    '&search=' + search +
    '&canceled=' + canceled +
    '&confirmed=true' +
    '&startAt[' + operator + ']=' + date +
    extraFilters
  )
;

export const confirmBookingParticipants = (id, confirm) => _get(`${URL}/${id}/confirm-participants?confirm=${confirm}`);

export const exportBookings = () =>
  _get(
    URL +
    '.export' +
    '?order[startAt]=asc' +
    '&club.id=' + clubId
  )
;

export const exportFormulas = (filters) => _get(`clubs/bookings.pdf?club.id=${clubId}${filters}`, ARRAY_BUFFER);

export const getBookingParticipants = (id) => _get(URL + '/participants/' + id);

export const getParticipantsByBooking = (id) => _get(URL + '/participants?itemsPerPage=120&order[createdAt]=asc&booking.id=' + id);

export const getParticipantsInQueueByBooking = (id) => _get(URL + '/participants?itemsPerPage=120&order[createdAt]=asc&inQueue=true&booking.id=' + id);

export const getClubBookingsByClientId = (clientId, itemsPerPage, page, search, canceled, date, operator) =>
  _get(
    URL +
    '?club.id=' + clubId +
    '&client.id=' + clientId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page +
    '&search=' + search +
    '&confirmed=true' +
    '&canceled=' + canceled +
    '&startAt[' + operator + ']=' + date
  )
;

export const getBooking = (id) => _get(URL + '/' + id);

export const getClubBookingCategories = () => _get(URL + '/categories?club.id=' + clubId);

export const getPlaygroundBookingsDate = (playground, startAt, endAt) => {
    return _get(
      URL + '?playgrounds.id=' + playground.id +
      '&canceled=false' +
      '&confirmed=true' +
      '&startAt[after]=' + startAt +
      '&startAt[before]=' + endAt
    );
  }

;

export const addBooking = (booking) => _post(URL, booking);

export const updateBooking = (bookingId, data) => _put(URL + '/' + bookingId, data);

export const deleteBooking = (bookingId, data) => _put(URL + '/' + bookingId, data);

export const getMultipleBookingsBySlot = (playgroundId, from, to, search = '') => {
  const MULTIPLE_BOOKING_URL = `/clubs/bookings?itemPerPage=500&confirmed=true&search=${search}&canceled=false&playgrounds.id=${playgroundId}&occur[from]=${from}&occur[to]=${to}`;

  return _get(MULTIPLE_BOOKING_URL);
}
